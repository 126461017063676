import React from 'react';

export default function Footer() {
  return (
    <footer className='footer container-fluid'>
      <div className='row'>
        <div className='col-sm-12 text-center'>
          <br />
          <div className='text-center'>
            <img
              alt=''
              src='/images/vetchat-powered-small.png'
              style={{
                paddingBottom: 10,
                width: 170,
                margin: '0 auto',
                paddingTop: 15
              }}
            />
          </div>
        </div>
      </div>
      <div className='row' style={{ paddingBottom: 40 }}>
        <div className='col-sm-12 text-center'>
          <div className='container'>
            <p>
              PETstock Vet Chat service is provided independently by PETstock’s
              third party service provider, Vetchat Services Pty Ltd (Vetchat).
            </p>
            <p>
              PETstock Vet Chat is subject to the  
              <a
                href='https://vetchat.com.au/terms-and-conditions'
                target='_blank'
                rel='noreferrer noopener'
              >
                Vetchat Terms and Conditions
              </a>
               and 
              <a
                href='https://vetchat.com.au/privacy-policy'
                target='_blank'
                rel='noreferrer noopener'
              >
                Privacy Policy
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
