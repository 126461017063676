import React, { Component } from 'react';
import FormInput from './FormInput';
import FormSelect from './FormSelect';
import FormDate from './FormDate';
import { PET_TYPES } from '../constants/constants';
import axios from 'axios';

export default class AddEditPetForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      image: '',
      imageFile: null,
      name: '',
      sex: 1,
      pet_species: '',
      breed: '',
      //   age: '',
      dob: new Date(),
      microchip: '',
      pet_id: null,
      filteredBreeds: [],
      petBreeds: []
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.setGender = this.setGender.bind(this);
    this.setImageFile = this.setImageFile.bind(this);
    this.clearImage = this.clearImage.bind(this);
  }

  async componentDidMount() {
    const { editPet } = this.props;
    await axios.get('/pet/all/breeds').then(res => {
      this.setState({ petBreeds: res.data });
    });

    if (editPet !== null) {
      this.setState({
        pet_id: editPet.id,
        name: editPet.name,
        breed: editPet.breed_id,
        pet_species: editPet.species_id,
        microchip: editPet.microchip ? AddEditPetForm.microchip : '',
        // age: editPet.age,
        dob: editPet.dob !== null ? new Date(editPet.dob) : '',
        sex: editPet.sex
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { petBreeds } = this.state;
    if (
      prevState.pet_species !== this.state.pet_species &&
      petBreeds !== undefined
    ) {
      let filteredBreeds = petBreeds.filter(
        breed => Number(breed.species_id) === Number(this.state.pet_species)
      );

      if (filteredBreeds === null) {
        filteredBreeds = [];
      }
      //   console.log(filteredBreeds);
      this.setState({ filteredBreeds });
    }
  }

  setImageFile(e) {
    const fileSelected = e.target.files[0];
    // allowed MIME types
    var mime_types = ['image/jpeg', 'image/png'];

    // validate MIME
    if (mime_types.indexOf(fileSelected.type) === -1) {
      alert('Error : Incorrect file type');
      return;
    }

    // validate file size
    if (fileSelected.size > 16 * 1024 * 1024) {
      alert('Error : Exceeded size 16MB');
      return;
    }

    const _PREVIEW_URL = URL.createObjectURL(fileSelected);

    document.getElementById(
      'pet-image-preview'
    ).style.backgroundImage = `url(${_PREVIEW_URL})`;

    this.setState({
      image: e.target.value,
      imageFile: fileSelected
    });
  }

  clearImage() {
    this.setState({ image: '', imageFile: null });
    const { editPet } = this.props;
    let existingImage = 'none';
    if (editPet != null && editPet.pet_image !== null) {
      existingImage = `url(${axios.defaults.baseURL +
        '/uploads/' +
        editPet.pet_image})`;
    }
    document.getElementById(
      'pet-image-preview'
    ).style.backgroundImage = existingImage;
  }

  setGender(e) {
    // console.log(e.target.value);
    this.setState({ sex: e.target.value });
  }
  async handleFormSubmit(e) {
    e.preventDefault();

    const newPetInfo = this.state;

    delete newPetInfo.loading;
    delete newPetInfo.image;
    delete newPetInfo.filteredBreeds;

    if (newPetInfo.imageFile !== null) {
      const PetImage = new FormData();
      PetImage.append('image', newPetInfo.imageFile, newPetInfo.imageFile.name);

      newPetInfo['image'] = PetImage;
    }

    delete newPetInfo.imageFile;

    this.props.savePet(newPetInfo);
  }

  render() {
    const { editPet } = this.props;
    let existingImage = 'none';
    if (
      editPet !== null &&
      editPet.pet_image !== null &&
      editPet.pet_image !== '' &&
      editPet.pet_image !== undefined
    ) {
      existingImage = axios.defaults.baseURL + '/uploads/' + editPet.pet_image;
    }

    return (
      <div className='row add-edit-pet-form'>
        <form
          onSubmit={this.handleFormSubmit}
          className='col-sm-6 col-sm-offset-3'
          encType='multipart/form-data'
        >
          <h2>{editPet === null ? 'Add new' : 'Edit'} pet</h2>
          <div className='row'>
            <div className='col-xs-12 vetchat-from-input'>
              <label className='sr-only'>Pet Image</label>
              <div
                id='pet-image-preview'
                className='pet-image preview'
                style={{
                  backgroundImage: `url(${existingImage})`
                }}
              ></div>
              <div className='form-group'>
                <input
                  type='file'
                  value={this.state.image}
                  onChange={this.setImageFile}
                  style={{
                    display: 'inline-block',
                    width: '75%',
                    border: 'none'
                  }}
                />
                <button
                  type='button'
                  disabled={this.state.image === ''}
                  className='btn btn-transparent'
                  onClick={this.clearImage}
                  style={{
                    display: 'inline-block',
                    width: '20%',
                    marginLeft: 8
                  }}
                >
                  Clear
                </button>
              </div>
            </div>
            <div className='col-xs-6'></div>
            <div className='clearfix'></div>
            <div className='col-sm-12 vetchat-from-input'>
              <label className=''>Pet Name</label>
              {FormInput(this, 'Pet Name', 'name', 'text')}
            </div>
            <div className='col-sm-6 vetchat-from-input'>
              <label className=''>Pet Type</label>
              {FormSelect(this, 'Pet Type', 'pet_species', PET_TYPES)}
            </div>
            <div className='col-sm-6 vetchat-from-input'>
              {(Number(this.state.pet_species) === 1 ||
                Number(this.state.pet_species) === 2) &&
              this.state.filteredBreeds.length > 0 ? (
                <>
                  <label className=''>Pet Breed</label>
                  {FormSelect(
                    this,
                    'Pet Breed',
                    'breed',
                    this.state.filteredBreeds
                  )}
                </>
              ) : (
                <div
                  className='empty-block'
                  style={{ minHeight: '104px' }}
                ></div>
              )}
            </div>
            <div className='clearfix'></div>
            {/* <div className='col-sm-6 vetchat-from-input'>
              <label className=''>Age</label>
              {FormInput(this, 'Pet Age', 'age', 'text')}
            </div> */}

            <div className='col-sm-6 vetchat-from-input'>
              <label className=''>DOB</label>
              {FormDate(this, 'DOB', 'dob')}
            </div>
            <div className='col-sm-6 vetchat-from-input'>
              <label className=''>Pet Gender</label>
              {FormSelect(this, 'Pet Gender', 'sex', [
                { id: 1, name: 'Male' },
                { id: 2, name: 'Female' }
              ])}
            </div>
            <div className='col-sm-12 vetchat-from-input'>
              <label className=''>Microchip</label>
              {FormInput(this, 'Microchip', 'microchip', 'text')}
            </div>
            <div className='col-sm-12 action-buttons'>
              <button type='submit' className='btn btn-vetchat2'>
                Save
              </button>
              <button
                className='btn btn-transparent'
                type='button'
                onClick={this.props.closeDialog}
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
