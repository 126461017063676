import React from "react";
import { PropagateLoader } from "react-spinners";

const style = {
  display: "flex",
  flex: 1,
  flexDirection: "row",
  justifyContent: "center"
};

export default class Loading extends React.Component {
  constructor(props) {
    super(props);

    let title = (props.title) ? props.title : "Loading...";

    this.state = {
      title: title
    };
  }

  render() {
    return (
      <div>
        <div className="text-center" style={style}>
          <h3>{this.state.title}</h3>
        </div>
        <br/>
        <div style={style}>
          <PropagateLoader color={"#36D7B7"} />
        </div>
      </div>
    );
  }
}
