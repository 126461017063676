import React from "react";
import FormInput from "../components/FormInput";
import AjaxError from "../components/AjaxError";
import Loading from "../components/Loading";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText
} from "@material-ui/core";
import { gaPageview } from "../utils/dev";

const styles = {
  dialog: {
    heading: {
      fontFamily: "Fresh Sans",
      marginLeft: 25,
      marginTop: 20,
      fontWeight: "bold"
    },
    text: {
      fontSize: 15,
      fontFamily: "Fresh Sans"
    },
    button: {
      fontSize: 15
    }
  }
};

export default class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);

    this.resetPwd = this.resetPwd.bind(this);

    this.state = {
      isLoading: false,
      error: false,
      errors: [],
      email: "",
      success: false
    };
  }
  componentDidMount() {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    gaPageview(this.props.location);
  }

  resetPwd() {
    this.setState({ isLoading: true });

    axios
      .post(axios.defaults.baseURL + "/user/resetpwdcode", {
        email: this.state.email
      })
      .then(() => {
        this.setState({ isLoading: false, success: true });
      })
      .catch(e => {
        this.setState({
          isLoading: false,
          error: true,
          errors: e.response.data.errors
        });
      });
  }

  render() {
    if (this.state.isLoading) {
      return <Loading />;
    }

    if (this.state.error) {
      return (
        <AjaxError
          open={this.state.error}
          errors={this.state.errors}
          action={() => {
            this.setState({ error: false });
          }}
        />
      );
    }

    return (
      <div className="container space-small-bottom">
        <Dialog open={this.state.success}>
          <p style={styles.dialog.heading}>Password Reset Code</p>
          <DialogContent>
            <DialogContentText style={styles.dialog.text}>
              A password reset code has been emailed to you. Please check your
              email and click on the link provided or click on the button below
              to reset your password.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Link to="/resetpwd" className="btn btn-success">
              Reset Password
            </Link>
          </DialogActions>
        </Dialog>

        {/* Heading */}
        <div className="row">
          <div className="col-sm-6 col-sm-push-3">
            <h1>Forgot Password</h1>
          </div>
        </div>

        {/* Email Label */}
        <div className="row">
          <label className="col-sm-6 col-sm-push-3">
            Please enter your email address:
          </label>
          <div className="clear" />
          {/* Email Input */}
          {FormInput(
            this,
            "Email Address",
            "email",
            "email",
            null,
            null,
            "col-sm-6 col-sm-push-3"
          )}
          {/* Submit Button */}
          <div className="clear" />
          <div className="col-sm-6 col-sm-push-3">
            <button
              className="btn btn-vetchat pull-right"
              onClick={this.resetPwd}
            >
              Submit
            </button>
          </div>
          <div className="clear" />
        </div>
      </div>
    );
  }
}
