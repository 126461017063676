import React, { Component, Fragment } from 'react';
import HowItWorks from '../components/pages/HowItWorks';
import OurVets from '../components/pages/OurVets';
import { TESTIMONIALS, PAGES } from '../constants/constants';
import { Link } from 'react-router-dom';

import isNZ from '../utils/isNZ';

const styles = {
  videoColor: {
    backgroundColor: '#43B02A'
  },
  chatColor: {
    backgroundColor: '#ee008c'
  }
};

export default class Home extends Component {
  componentDidMount() {
    const thisPage = PAGES['home'];
    document.title = thisPage.title;
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    document.getElementById('main-content').style.margin = '0 0 0';
  }

  render() {
    const isNZsite = isNZ();

    const testimonialsView = TESTIMONIALS.map((quote, quoteIndex) => {
      const saying = quote.text.map((text, index) => {
        return (
          <p
            key={`quote-text-${index}`}
            className={index === 0 ? 'main-quote' : ''}
          >
            {text}
          </p>
        );
      });

      const testimonialName = quote.from.split(',')[0]; //split from data and use only name fro NZ site

      const clearDiv =
        (quoteIndex + 1) % 3 === 0 ? (
          <div className='clear' key={`cleardiv-${quoteIndex}`} />
        ) : (
          ''
        );
      return (
        <Fragment key={`testimonial-${quoteIndex}`}>
          <div className='col-md-4'>
            <div className='single-testimonial'>
              <div className='star-rating'>
                <i className='glyphicon glyphicon-star' />
                <i className='glyphicon glyphicon-star' />
                <i className='glyphicon glyphicon-star' />
                <i className='glyphicon glyphicon-star' />
                <i className='glyphicon glyphicon-star' />
              </div>
              <div className='quote'>
                {saying}
                <p className='quoteBy'>
                  - {isNZsite ? testimonialName : quote.from}
                </p>
              </div>
            </div>
          </div>
          {clearDiv}
        </Fragment>
      );
    });

    return (
      <Fragment>
        <div className='container-fluid home-banner'>
          <div className='banner-logo'>
            <img src='/images/PETstock_vet_chat_logo.png' alt='Logo' />
          </div>
        </div>
        <div className='container homePage'>
          <div className='row'>
            {/* TOp COntent */}
            <div className='col-sm-12  top-content'>
              <h2 className='section-title'>Chat with a Vet online</h2>
              <p className='desc'>
                {isNZsite ? (
                  <>
                    <b>PETstock Vet Chat </b>connects you with an experienced
                    licensed Veterinarian via video or text, 8am-2am NZST / NZDT
                    every day.
                  </>
                ) : (
                  <>
                    <b>PETstock Vet Chat </b>connects you with an experienced
                    Australian licensed Veterinarian via video or text, 6am -
                    midnight AEDT every day.
                  </>
                )}
              </p>
              <p className='desc'>Our Vets are on standby to help.</p>
            </div>

            {/*** Chat Packages ***/}
            <div
              className='col-sm-12'
              style={{ paddingLeft: 0, paddingRight: 0 }}
            >
              <div className='purchase-table text-center'>
                <div className='purchase-header'>
                  <div className='purchase-title text-center'>
                    <span className='heading'>
                      Online Veterinary Consultation
                    </span>
                  </div>
                  <div className='purchase-price'>
                    <h3 className='price'>
                      <span>$39.95</span>
                      <small style={{ textTransform: 'none' }}>
                        per consultation
                      </small>
                    </h3>
                  </div>
                </div>
                <div className='purchase-type'>
                  {/* chat consult */}
                  <div className='type type-chat'>
                    <div className='title' style={styles.chatColor}>
                      <span>CHAT</span>
                    </div>
                    <div className='details'>
                      <ul>
                        <li>
                          <i className='fa fa-check' />
                          One (1) chat consultation
                        </li>
                        <li>
                          <i className='fa fa-check' />
                          Upload pics and video
                        </li>
                        <li>
                          <i className='fa fa-check' />
                          Vet help within minutes
                        </li>
                      </ul>
                      <span>
                        <Link
                          to={'chat-purchase'}
                          className='btn'
                          style={styles.chatColor}
                        >
                          chat now
                        </Link>
                      </span>
                    </div>
                  </div>
                  <div className='type type-video'>
                    <div className='title' style={styles.videoColor}>
                      <span>VIDEO</span>
                    </div>
                    <div className='details'>
                      <ul>
                        <li>
                          <i className='fa fa-check' />
                          One (1) video consultation
                        </li>
                        <li>
                          <i className='fa fa-check' />
                          Face to face
                        </li>
                        <li>
                          <i className='fa fa-check' />
                          Vet help within minutes
                        </li>
                      </ul>
                      <span>
                        <Link
                          to={'video-purchase'}
                          className='btn'
                          style={styles.videoColor}
                        >
                          video now
                        </Link>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <HowItWorks />
        <OurVets />
        {/* Testimonials */}
        <div className='container-fluid testimonials'>
          <div className='row'>
            <div className='col-sm-12'>
              <div className='container'>
                <div className='row'>
                  <div className='col-sm-12'>
                    <h2 className='section-title'>
                      What our pet carers are saying
                    </h2>
                    <p className='text-center'>
                      From our pet carers themselves, read how they've received
                      peace of mind, reassurance, understanding of next steps
                      and even saved pets lives.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-sm-12'>
              <div className='container'>
                <div className='row'>{testimonialsView}</div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
