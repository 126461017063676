import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Loading from '../components/Loading';
import { apiError } from '../utils/errors';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import AddEditPetForm from '../components/AddEditPetForm';
import EditUserForm from '../components/EditUserForm';
import { ConsultationHistory } from 'vetchat-react';
import VetConsultations from '../components/VetConsultations';
import { gaPageview } from '../utils/dev';

// import UserSubscription from '../components/UserSubscription';

const styles = {
  dialog: {
    heading: {
      marginLeft: 25,
      marginTop: 20,
      fontWeight: 'bold'
    },
    text: {
      fontSize: 15
    },
    button: {
      fontSize: 15
    }
  }
};

// Consultation Limit for subscribed User
// const subscribedConsultationsLimit = 6;

export default class MyAccount extends React.Component {
  // Constructor
  constructor(props) {
    super(props);

    // Bindings
    this.startChat = this.startChat.bind(this);
    this.setChatType = this.setChatType.bind(this);
    this.toggleLargeDialog = this.toggleLargeDialog.bind(this);
    this.savePetInfo = this.savePetInfo.bind(this);
    this.loadConsultsForVet = this.loadConsultsForVet.bind(this);
    this.changeSorting = this.changeSorting.bind(this);
    this.closeDialog = this.closeDialog.bind(this);

    // interval for reloading consultations
    this.intervalId = null;

    // Setup the state
    this.state = {
      user: {},
      pets: [],
      isLoading: true,
      dialog: false,
      error_dialog: false,
      pet_id: 0,
      pet_issue: '',
      chatType: '',
      loading_msg: 'Loading...',
      largeDialog: false,
      largeDialogContent: '',
      editPet: null,
      loadedTime: null,
      display: 'All',
      subscribedConsultations: 0
    };
  }

  /**
   * Load User's Pets
   */
  async loadPets() {
    await axios
      .get(axios.defaults.baseURL + '/pet')
      .then(response => {
        let pets = response.data.pets;

        if (pets.length === 1) {
          this.setState({ pet_id: pets[0].id });
        }

        /* const existingPets = localStorage.getItem("pets");
        if (existingPets !== null && JSON.stringify(pets) !== existingPets) {
          this.setState({ pets: pets });
          return;
        } */

        this.setState({ pets: pets });
        localStorage.setItem('pets', JSON.stringify(pets));
      })
      .catch(e => apiError(this, e));
  }

  /**
   * Load User Information
   */
  async loadUser() {
    await axios
      .get(axios.defaults.baseURL + '/user/me')
      .then(async response => {
        let user = response.data.user;

        if (user.type === 2 || user.type === 3) {
          user.consultations = await this.loadConsultsForVet();
          this.setState({ loadedTime: moment() });
        }

        localStorage.setItem('user', JSON.stringify(user));

        //countSubscribed Consultations
        let subs_consultations = user.subscription
          ? user.subscription.consultations
          : null;

        console.log('subscription Consultations: ', subs_consultations);
        if (subs_consultations !== null) {
          subs_consultations = JSON.parse(subs_consultations);
        } else {
          subs_consultations = [];
        }

        this.setState({
          user: user,
          subscribedConsultations: subs_consultations.length
        });

        await this.loadPets();
      })
      .catch(e => apiError(this, e));
  }

  async loadConsultsForVet() {
    return await axios
      .get(axios.defaults.baseURL + '/consultation')
      .then(response => {
        if (this.intervalId === null) {
          this.intervalId = setInterval(async () => {
            // console.log("reloading content: ", moment().format("LTS"));
            let user = JSON.parse(localStorage.getItem('user'));
            user.consultations = await this.loadConsultsForVet();
            this.setState({ user: user, loadedTime: moment() });
            await this.loadPets();
          }, 30000);
        }

        return response.data.consultations;
      });
  }

  async componentDidMount() {
    document.title = 'My Account | VetChat';
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    gaPageview(this.props.location);

    // Load User, then load Pets
    await this.loadUser();

    // Once loaded set loading to false
    await this.setState({ isLoading: false });
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  setChatType(type) {
    //check is time between (6:00AM and 12:00AM)
    const format = 'hh:mm:ss';
    const afterTime = moment('06:00:00', format);
    const beforeTime = moment('23:59:59', format);
    const currentTime = moment();
    const validTime = currentTime.isBetween(afterTime, beforeTime);
    if (validTime) {
      this.setState({ dialog: true, chatType: type });
    } else {
      this.setState({ error_dialog: true });
    }
  }

  async startChat() {
    if (parseInt(this.state.pet_id) === 0) {
      alert('Please select a pet');
      return;
    }

    if (this.state.pet_issue === '') {
      alert('Please provide the details of your enquiry');
      return;
    }

    this.setState({ isLoading: true });

    let chat_type = this.state.chatType === 'text' ? 1 : 2;

    await axios
      .post(axios.defaults.baseURL + '/consultation', {
        pet_id: this.state.pet_id,
        pet_issue: this.state.pet_issue,
        type: chat_type
      })
      .then(response => {
        if (response.data.errors !== undefined) {
          this.setState({ isLoading: false });
          apiError(this, response.data.errors);
          console.log(response.data.errors);
        }
        let consultation_id = response.data.consultation.id;

        //add cinsultation to subscription
        axios.post(
          axios.defaults.baseURL +
            '/user/subscriptionUsage/' +
            this.state.user.subscription.subs_id,
          { consultation: consultation_id }
        );

        //redirect user to consultation page
        this.props.history.push(
          '/' + this.state.chatType + '-chat/' + consultation_id
        );
      })
      .catch(e => {
        this.setState({ isLoading: false });
        apiError(this, e);
      });
  }

  toggleLargeDialog(content) {
    const { largeDialog } = this.state;
    if (largeDialog === true) {
      this.setState({ largeDialog: false, largeDialogContent: '' });
      if (this.state.largeDialogContent === 'addEditPet') {
        this.setState({ editPet: null });
      }
    } else {
      this.setState({ largeDialog: true, largeDialogContent: content });
    }
  }

  showLoading(title) {
    return <Loading title={title} />;
  }

  changeSorting(showType) {
    const types = ['All', 'Completed', 'In Progress', 'Available'];
    if (types.indexOf(showType) !== -1 && showType !== this.state.display) {
      this.setState({ display: showType });
    }
  }

  async savePetInfo(petInfo) {
    this.toggleLargeDialog();
    let pet_image = '';

    //upload pet image
    if (petInfo.image !== undefined) {
      await axios
        .post('/upload', petInfo.image)
        .then(res => {
          pet_image = res.data.filename;
        })
        .catch(err => {
          console.log('error uploading image');
        });
    }

    if (petInfo.pet_id === null) {
      // add new pet
      await axios
        .post('/pet', { ...petInfo, pet_image })
        .then(response => {
          // return;
          let { pets } = this.state;
          pets.push(response);
          this.setState({ pets });
        })
        .catch(err => {
          console.log('had error adding pet: ', err);
        });
    } else {
      // update pet
      const updateInfo = {
        name: petInfo.name,
        id: petInfo.pet_id,
        species_id: petInfo.pet_species,
        breed_id: petInfo.breed,
        microchip: petInfo.michrochip,
        // age: petInfo.age,
        sex: petInfo.sex,
        dob: petInfo.dob
      };

      if (pet_image !== '') {
        updateInfo['pet_image'] = pet_image;
      }
      await axios
        .post('/pet/update', updateInfo)
        .then(response => {
          // console.log(response);
          let { pets } = this.state;
          for (let pet in pets) {
            if (pets[pet].id === petInfo.pet_id) {
              pets[pet] = response.data.pet[0];
            }
          }

          this.setState({ pets });
        })
        .catch(err => {
          console.log('had error adding pet: ', err);
        });
    }
  }

  closeDialog() {
    console.log('close Dialog initialized');
    this.setState({ dialog: false });
  }

  render() {
    if (this.state.isLoading) {
      return (
        <div className='container space-top space-bottom'>
          <Loading title={this.state.loading_msg} />
        </div>
      );
    }

    //load only consultations for vets and admins
    if (this.state.user.type === 2 || this.state.user.type === 3) {
      return (
        <VetConsultations {...this.state} changeSorting={this.changeSorting} />
      );
    }

    const {
      error_dialog,
      largeDialog,
      largeDialogContent,
      editPet,
      user
    } = this.state;

    return (
      <>
        <div className='myaccount-header'>
          <div className='container'>
            <div className='row text-right'>
              <h4 className='col-sm-12'>My PETstock Account</h4>
            </div>
          </div>
        </div>
        <div className='container'>
          {/* Consultation Offline Dialog */}
          <Dialog open={error_dialog}>
            <p style={styles.dialog.heading}>Error</p>
            <DialogContent>
              Vetchat is currently offline (Online between 6:00am to 12:00am SYD
              Time). Please call your nearest Vet.
            </DialogContent>
            <DialogActions>
              <button
                className='btn btn-transparent'
                onClick={() => this.setState({ error_dialog: false })}
              >
                OK
              </button>
            </DialogActions>
          </Dialog>

          <div className='row'>
            <div className='col-sm-12 account-action-btns'>
              <div
                className='content-link link-chevron text-right'
                onClick={() => {
                  this.toggleLargeDialog('editUser');
                }}
              >
                Edit my account details
              </div>
              {/* {this.state.user && this.state.user.subscription !== null && (
                <>
                  <div
                    className='content-link link-chevron text-right'
                    onClick={() => {
                      this.toggleLargeDialog('subscription');
                    }}
                  >
                    Subscription
                  </div>
                  
                </>
              )} */}
              <div
                className='content-link link-chevron text-right'
                onClick={() => {
                  this.props.history.push('/logout');
                }}
              >
                Logout
              </div>
            </div>
            <div className='clearfix' />
            <div className='col-sm-12'>
              <h3>My Consultation History</h3>
              <hr />
              <div className='row consultation-history'>
                <ConsultationHistory
                  showLoading={this.showLoading}
                  api={axios.defaults.baseURL}
                  axios={axios}
                  moment={moment}
                  user={this.state.user.id}
                />
              </div>
            </div>
          </div>
          <Dialog
            open={largeDialog}
            onClose={this.toggleLargeDialog}
            fullWidth={true}
            maxWidth={`lg`}
          >
            <DialogContent>
              {largeDialogContent === 'addEditPet' && (
                <AddEditPetForm
                  editPet={editPet}
                  closeDialog={this.toggleLargeDialog}
                  savePet={this.savePetInfo}
                />
              )}
              {largeDialogContent === 'editUser' && (
                <EditUserForm
                  user={user}
                  closeDialog={this.toggleLargeDialog}
                />
              )}
            </DialogContent>
          </Dialog>
        </div>
      </>
    );
  }
}
