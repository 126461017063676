import React from "react";
import ReactGa from "react-ga";
function isDev() {
  return "_self" in React.createElement("div");
}

function gaPageview(location) {
  try {
    ReactGa.pageview(location.pathname);
  } catch (e) {
    console.log("react pageView analytics: ", e);
  }
}

export { isDev, gaPageview };
