import React from 'react';
import {
  // CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  StripeProvider,
  Elements,
  injectStripe
} from 'react-stripe-elements';
import axios from 'axios';
import jwtDecode from 'jwt-decode';

const host = window.location.hostname;
//check for uat and localhost to confirm Prod server
const isProd = host.indexOf('uat') === -1 && !host.includes('localhost');
const STRIPE_PK = isProd
  ? process.env.REACT_APP_STRIPE_PK_PROD
  : process.env.REACT_APP_STRIPE_PK_TEST;

const createOptions = (fontSize, padding) => {
  return {
    style: {
      base: {
        fontSize,
        color: '#424770',
        letterSpacing: '0.025em',
        fontFamily: 'Source Code Pro, monospace',
        '::placeholder': {
          color: '#aab7c4'
        },
        padding
      },
      invalid: {
        color: '#9e2146'
      }
    }
  };
};

class _CardForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      userEmail: '',
      error: ''
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  generateToken = async () => {
    // e.preventDefault();
    await this.props.stripe
      .createToken()
      .then(payload => {
        console.log('token generated: ', payload);
        // this.props.onComplete(payload);
        if (payload.hasOwnProperty('token')) {
          this.props.onComplete(payload.token);
          this.setState({ error: '' });
        } else if (payload.hasOwnProperty('error')) {
          this.setState({ error: payload.error.message });
        }
      })
      .catch(err => {
        console.log('Error generating token: ', err);
      });
  };

  handleSubmit = async ev => {
    ev.preventDefault();
    if (this.props.stripe) {
      //validate email input
      if (
        this.state.userEmail === '' ||
        !this.validEmail(this.state.userEmail)
      ) {
        console.log('Invlaid Email');
        return;
      }

      this.setState({ isSubmitting: true });
      await this.props.stripe.createToken().then(async payload => {
        //payload is card token
        await axios
          .post(axios.defaults.baseURL + '/pay', {
            card: payload,
            amount: this.props.amount,
            consult: this.props.consult.title,
            user: this.state.userEmail
          })
          .then(response => {
            if (
              response.data.hasOwnProperty('status') &&
              response.data.status === 'succeeded'
            ) {
              let { consult } = this.props;
              consult['paid'] = 1;
              sessionStorage.setItem(
                'Stripe_Customer',
                JSON.stringify(response.data.customer)
              );
              sessionStorage.setItem(
                'Current_Consult',
                JSON.stringify(consult)
              );
              this.props.onComplete();
            } else {
              console.log('No Successful payment was made');
            }
          })
          .catch(err => {
            console.log('error on submitting form: ', err);
          }) /* .then((response)=>{
              if(response.data.hasOwnProperty('error')) {
                console.log(response.data.error);
              }
            }) */;
        this.setState({ isSubmitting: false });
      });
    } else {
      console.log("Stripe.js hasn't loaded yet.");
    }
  };

  validEmail(email) {
    if (email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      return true;
    } else {
      return false;
    }
  }

  componentDidMount() {
    let { userEmail } = this.state;
    if (userEmail === '') {
      if (localStorage.getItem('user') !== null) {
        userEmail = JSON.parse(localStorage.getItem('user')).email;
      } else if (localStorage.getItem('token') !== null) {
        const decoded = jwtDecode(localStorage.getItem('token'));

        userEmail = decoded.id;
      }
      if (userEmail !== '' && userEmail !== undefined) {
        this.props.setEmail(userEmail);
      }
    }
  }
  render() {
    return (
      <div className='purchase-form-stripe'>
        <div className='row '>
          <div className='col-sm-12 stripe-element hidden'>
            <label>
              Email Address
              <input
                type='text'
                onChange={e => {
                  const newValue = e.target.value;
                  this.props.setEmail(newValue);
                }}
                placeholder='Email Address'
                value={this.props.email}
              />
            </label>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-12 stripe-element'>
            <label>
              Card number
              <CardNumberElement
                {...createOptions(this.props.fontSize)}
                onBlur={() => {
                  this.generateToken();
                }}
              />
            </label>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6 stripe-element'>
            <label>
              Expiration date
              <CardExpiryElement
                {...createOptions(this.props.fontSize)}
                onBlur={() => {
                  this.generateToken();
                }}
              />
            </label>
          </div>
          <div className='col-md-6 stripe-element'>
            <label>
              CVC
              <CardCVCElement
                {...createOptions(this.props.fontSize)}
                onBlur={() => {
                  this.generateToken();
                }}
              />
            </label>
          </div>
          <div className='col-sm-12 message-error'>
            {this.state.error !== '' && (
              <p className='text-danger'>{this.state.error}</p>
            )}
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12 text-center'>
            <p style={{ fontSize: 12 }}>
              *Vetchat will not save your card details.
            </p>
            <p>
              <img
                src='/images/powered_by_stripe.png'
                alt='powered by stripe'
              />
            </p>
          </div>
        </div>
      </div>
    );
  }
}
const CardForm = injectStripe(_CardForm);

export default function PurchaseForm(props) {
  return (
    <StripeProvider apiKey={STRIPE_PK}>
      <Elements>
        <CardForm fontSize='18px' {...props} />
      </Elements>
    </StripeProvider>
  );
}
