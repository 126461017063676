import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class NotFound extends Component {
  render() {
    return (
      <div className='container text-center'>
        <div className='row'>
          <div className='col-12 space-small-top'>
            <div className='jumbotron'>
              <h1>404</h1>
              <p>
                This page doesn't exist! Please head to back to&nbsp;
                <Link to='/' replace>
                  home
                </Link>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
