const loadStripeJs = callback => {
  const existingScript = document.getElementById('stripeJs');

  if (existingScript === null) {
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/';
    script.id = 'stripeJs';
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      if (callback) callback();
    };
  }

  if (existingScript !== null && callback) callback();
};

export default loadStripeJs;
