// Core Libs
import React, { Component, Fragment } from "react";
import axios from "axios";
import moment from "moment";
import jwtDecode from "jwt-decode";

// Components
import AjaxError from "../components/AjaxError";

import { apiError } from "../utils/errors";
import { isDev } from "../utils/dev";

import Loading from "../components/Loading";
import { VideoChat } from "vetchat-react";
import { sendVetClinicEmail } from "../utils/VetClinicEmailHandler";

// Constants
const API_KEY = "d8f35e3c9e5cdc8625e4405b";
const CONFIG_URL = `https://api.simplewebrtc.com/config/guest/${API_KEY}`;

export default class VetchatVideo extends Component {
  constructor(props) {
    super(props);

    if (
      !props.match.params.consultation_id &&
      !props.location.state.consultation_id
    ) {
      alert("Error: No Consultation ID Provided");
      return;
    }

    let consultation_id = props.match.params.consultation_id
      ? props.match.params.consultation_id
      : props.location.state.consultation_id;

    if (consultation_id === undefined || isNaN(Number(consultation_id))) {
      this.props.history.push(`/pricing`);
      return;
    }

    let singleToken = localStorage.getItem("singleToken");
    if (localStorage.getItem("token") === null && singleToken !== null) {
      const singleTokenDecoded = jwtDecode(singleToken);
      const { exp } = singleTokenDecoded;
      if (Date.now() < exp * 1000) {
        // return false;
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + singleToken;
      }
    }

    this.state = {
      isLoading: true,
      consultation_id: consultation_id,
      end_chat: false,
      consultation: {}
    };
  }

  async componentDidMount() {
    document.title = "Video Chat | VetChat";
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    await this.getConsultationInfo();

    document.body.classList.add("chat-page");
  }

  componentWillUnmount() {
    document.body.classList.remove("chat-page");
  }

  // Get Consultation Info from server
  async getConsultationInfo() {
    await axios
      .get(
        axios.defaults.baseURL + "/consultation/" + this.state.consultation_id
      )
      .then(response => {
        if (response.data.hasOwnProperty("consultation")) {
          if (response.data.consultation.type === 1) {
            this.props.history.push(`/text-chat/${this.state.consultation_id}`);
            return;
          }

          this.setState({
            consultation: response.data.consultation,
            isLoading: false
          });
        } else if (response.data.hasOwnProperty("error")) {
          this.handleApiError(this, response.data.error);
        }
      })
      .catch(e => apiError(this, e));
  }
  handleError(error, errors) {
    return (
      <AjaxError
        open={error}
        errors={errors}
        // action={}
      />
    );
  }

  showLoading(title) {
    return <Loading title={title} />;
  }

  checkDev() {
    return isDev();
  }

  summaryNotes(data) {
    sendVetClinicEmail(data);
  }

  render() {
    const { isLoading, consultation, consultation_id } = this.state;

    console.log('state', this.state);

    return (
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            {isLoading === true && <Loading />}

            {isLoading === false && (
              <Fragment>
                <VideoChat
                  axios={axios}
                  api={axios.defaults.baseURL}
                  apiError={apiError}
                  consultation={consultation}
                  moment={moment}
                  consultation_id={consultation_id}
                  showLoading={this.showLoading}
                  configUrl={CONFIG_URL}
                  handleError={this.handleError}
                  isDev={this.checkDev}
                  summaryNotes={this.summaryNotes}
                />

                {/* {this.state.consultation.outcome_id === null && (
                  <VetchatTextChat 
                  {...this.props} 
                  showLoading={this.showLoading}
                  handleError={this.handleError}
                  apiError={apiError}
                  isDev={this.checkDev}
                  api={axios.defaults.baseURL}
                  axios={axios}
                  consultation={consultation}
                  moment={moment}
                  />
                )} */}
              </Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}
