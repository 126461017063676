import React from 'react';
import { VETS } from '../../constants/constants';

import isNZ from '../../utils/isNZ';

export default function OurVets() {
  const aboutVet = (about, vet) => {
    return about.map((info, index) => {
      return <li key={'info-' + vet + '-' + index}>{info}</li>;
    });
  };

  const isNZsite = isNZ();

  const VetSlides = VETS.map((vet, index) => {
    return (
      <div
        key={('slideItem-', +index)}
        className='vet col-sm-4 text-center vet-detail'
        // style={{ backgroundColor }}
      >
        <div className='detail'>
          <div className='title row'>
            <div className='col-xs-4 image'>
              <img alt={vet.vet} src={`/images/our-vets/${vet.image}`} />
            </div>
            <div className='col-xs-8 text-left'>
              <h4 className='vet-name'>{vet.vet}</h4>
              <p>{vet.position}</p>
            </div>
            <div className='clear'></div>
            <div className='separator'>
              <hr />
            </div>
          </div>
          <div className='info'>
            <ul>{aboutVet(vet.about, index)}</ul>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className='ourVets container'>
      <div className='row'>
        <div className='col-sm-12'>
          <h2 className='section-title'>Meet some of our Vets</h2>
          <p className='text-center'>
            {isNZsite ? (
              <>
                Our awesome Vets are board certified with a minimum of 5 years
                experience, and are passionate about improving pet health
                incomes with easy access to the right advice.
              </>
            ) : (
              <>
                Our awesome Vets are Australian board certified with a minimum
                of 5 years experience, and are passionate about improving pet
                health incomes with easy access to the right advice.
              </>
            )}
          </p>
          <div className='carousel-vets row'>{VetSlides}</div>
        </div>
      </div>
    </div>
  );
}
