import React from "react";

/**
 * Generate a form input block
 * @param {object} component
 * @param {string} name
 * @param {string} variable
 * @param {array} options
 */
const FormSelect = (component, name, variable, options, colClass, required) => {
  colClass = (colClass === undefined) ? "form-group" : colClass + " form-group";
  required = required === undefined ? false : required;

  const reqField = required ? (
    // <div className="col-sm-1">
      <span className="required-star" style={{ color: "red" }}>*</span>
    // </div>
  ) : (
    ""
  );

  return (
      <div className={colClass}>
        {reqField}
        <select
          className="form-control"
          onChange={event =>
            component.setState({ [variable]: event.target.value })
          }
          value={component.state[variable]}
        >
          <option value="0">{/*Please Select */}{name}</option>
          {options.map(option => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
        </select>
      </div>
  );
};

export default FormSelect;
