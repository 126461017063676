import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import AjaxError from '../components/AjaxError';
import Loading from '../components/Loading';
import { isLoggedIn } from '../utils/auth';
import { gaPageview } from '../utils/dev';
export default class Login extends React.Component {
  // Constructor
  constructor(props) {
    super(props);

    // Bindings
    this.submitLogin = this.submitLogin.bind(this);

    // Setup the state
    this.state = {
      isLoading: false,
      error: false,
      errors: [],
      password: '',
      email: ''
    };
  }

  componentDidMount() {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    document.getElementById('main-content').style.margin = '0 0 0';
    gaPageview(this.props.location);
  }

  componentWillUnmount() {
    document.getElementById('main-content').style.margin = '0 0 20px';
  }

  componentWillMount() {
    //redirect if user is logged in
    if (isLoggedIn()) {
      this.props.history.push(`/myaccount`);
    }
  }

  submitLogin(e) {
    e.preventDefault();
    this.setState({
      isLoading: true
    });

    axios
      .post('/login', this.state)
      .then(response => {
        let token = response.data.token;
        //removed  singleToken if exists
        localStorage.removeItem('singleToken');
        localStorage.setItem('token', token);
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        this.props.history.push(`/myaccount`);
        window.location.reload();
      })
      .catch(e => {
        this.setState({
          isLoading: false,
          error: true,
          errors: [
            "Sorry, we don't recognise your details.\nPlease check your email address and password and try again."
          ] //e.response.data.errors
        });
      });
  }

  render() {
    if (this.state.isLoading) {
      return <Loading />;
    }

    if (this.state.error) {
      return (
        <AjaxError
          open={this.state.error}
          errors={this.state.errors}
          action={() => {
            this.setState({ error: false });
          }}
        />
      );
    }

    return (
      <div className='container-fluid container-login'>
        <div className='row'>
          <div className='col-md-8 col-md-offset-2 login-content'>
            <form className='login'>
              <div className='image'>
                <img
                  src='/images/petstock_logo_big.png'
                  alt='vetchat logo'
                  style={{ maxWidth: '50%', margin: '0 auto' }}
                />
                <p className='space-top'>Welcome back!</p>
              </div>
              <div className='form-group'>
                <label className='sr-only'>Email address</label>
                <input
                  type='email'
                  className='form-control login-email'
                  placeholder='Email'
                  required
                  onChange={event =>
                    this.setState({ email: event.target.value })
                  }
                  value={this.state.email}
                />
                <span
                  className='glyphicon glyphicon-envelope form-control-feedback'
                  aria-hidden='true'
                />
              </div>
              <div className='form-group'>
                <label className='sr-only'>Password</label>
                <input
                  type='password'
                  className='form-control login-password'
                  placeholder='Password'
                  required
                  onChange={event =>
                    this.setState({ password: event.target.value })
                  }
                  value={this.state.password}
                  autoComplete='password'
                />
                <span
                  className='glyphicon glyphicon-lock form-control-feedback'
                  aria-hidden='true'
                />
              </div>
              <div className='extra-actions'>
                <label className='checkbox-container text-left'>
                  <input type='checkbox' />
                  &nbsp;Remember me
                </label>
                <Link to='/forgotpassword' className='forgot-link'>
                  Forgot your password?
                </Link>
                <div className='clear' />
                <div className='submit-login'>
                  <button
                    className='btn btn-full btn-login'
                    onClick={this.submitLogin}
                  >
                    Login
                  </button>
                </div>
                {/* <p className="text-signup">Don't have an account? <Link to="/signup" className="signup-link">Sign up now!</Link></p> */}
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
