import React from 'react';

export default function HowItWorks() {
  return (
    <div className='container how-vetchat-works text-center'>
      <div className='row'>
        <div className='col-sm-12'>
          <h2 className='hiw-title'>How does PETstock Vet Chat work?</h2>
          <p style={{ paddingBottom: 10 }}>
            We make chatting to a trusted Vet online as easy as 1, 2, 3, from
            your phone, tablet or computer.
          </p>
        </div>
        <div className=' col-sm-12'>
          <div className='row row-eq-height'>
            <div className='col-sm-4 how-steps'>
              <div className='steps-image'>
                <img src='/images/choose-icon-small1.png' alt='choose-how' />
              </div>
              <div className='steps-content'>
                <h2 className='steps-header'>1</h2>
                <h3>Choose how you want to speak to a Vet</h3>
                <p>
                  Our experienced Vets provide realtime consults via chat and
                  video call.
                </p>
              </div>
            </div>
            <div className='col-sm-4 how-steps'>
              <div className='steps-image'>
                <img src='/images/payment-icon-small1.png' alt='choose-how' />
              </div>
              <div className='steps-content'>
                <h2 className='steps-header'>2</h2>

                <h3>Pay for your consult securely online</h3>
                <p>
                  When you’ve chosen your preferred consult, pay securely with a
                  credit or debit card using our trusted gateway, Stripe.
                </p>
              </div>
            </div>
            <div className='col-sm-4 how-steps'>
              <div className='steps-image'>
                <img src='/images/vet-icon-small1.png' alt='choose-how' />
              </div>
              <div className='steps-content'>
                <h2 className='steps-header'>3</h2>

                <h3>Get expert vet advice within minutes</h3>
                <p>
                  Your Vet will assess your pet online, guide you through a
                  virtual examination and recommend a treatment plan.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
