function apiError(component, e) {
  if (e.response === undefined || e.response === null) {
    alert("An Error Occurred");
    console.log("error", e);
    return;
  }

  if (e.response.data !== undefined && e.response.data === "Unauthorized") {
    alert("Please login");
    component.props.history.push(`/login`);
    return;
  }

  if (e.response.errors !== undefined && e.response.errors !== null) {
    let errorString = "";
    e.response.data.errors.forEach(error => {
      errorString += error + "\n";
    });
    alert(errorString);
    return;
  }

  alert("An Error Occurred");
  console.log(e.response);
  console.log("error", e);
}

export { apiError };
