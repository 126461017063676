import React from "react";
import DatePicker from "react-datepicker";

/**
 * Generate a form input block
 * @param {object} component
 * @param {string} name
 * @param {string} variable
 */
const FormDate = (component, name, variable, colClass, required) => {
  colClass = (colClass === undefined) ? "form-group" : colClass + " form-group";
  required = required === undefined ? false : required;

  const reqField = required ? (
    // <div className="col-sm-1">
      <span className="required-star" style={{ color: "red" }}>*</span>
    // </div>
  ) : (
    ""
  );
  return (
      <div className={colClass}>
        {reqField}
        {/*
        <label>{name}</label>
        */}
        <DatePicker
          className="form-control"
          dateFormat="dd/MM/yyyy"
          onChange={date => component.setState({ [variable]: date })}
          selected={component.state[variable]}
          placeholderText={name}
        />
      </div>
  );
};

export default FormDate;
