import axios from "axios";

const sendVetClinicEmail = async data => {
  console.log("send summary report with data: ", data);
  // if (data.consultationId === undefined || data.email === "") {
  //   return null;
  // }

  await axios
    .post(`/consultation/consultSummary/${data.consultationId}`, data)
    .then(res => {
      console.log("submitted vet clinic email with server messgae: ", res);
    })
    .catch(err => {
      console.log("Failed to send email to vet clinic: ", err);
    });
};

export { sendVetClinicEmail };
