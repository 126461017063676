import React from 'react';
import { Link } from 'react-router-dom';
/**
 * Nav Bar Links
 * Display relevant nav links when user is signed in or signed out
 */
const NavLinks = function() {
  let links = [];
  links.push(
    <li key='video'>
      <Link to='/video-purchase'>Video Call</Link>
    </li>
  );
  links.push(
    <li key='chat'>
      <Link to='/chat-purchase'>Text Chat</Link>
    </li>
  );
  links.push(
    <li key='contact'>
      <Link to='/contact'>Contact</Link>
    </li>
  );

  return links;
};

export default NavLinks;
