import jwtDecode from 'jwt-decode';
import moment from 'moment';
import axios from 'axios';

//cehck token and its expiry
const isLoggedIn = () => {
  //check token and if valid redirect
  try {
    let token = localStorage.getItem('token');
    if (token !== undefined && token !== null) {
      token = jwtDecode(token);
      const tokenExpiry = moment.unix(token.exp);
      // console.log("expiry time is: ",tokenExpiry.format("dddd, MMMM Do YYYY, h:mm:ss a"));
      if (moment().isAfter(tokenExpiry)) {
        // clear local storage for token expired
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        return false;
      } else {
        // console.log("valid Token do redirect");
        return true;
      }
    } else {
      return false;
    }
  } catch (err) {
    console.log('error getting token: ', err);
    return false;
  }
};

const setLoggedIn = token => {
  localStorage.removeItem('singleToken');
  localStorage.setItem('token', token);
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
};

const isValidPassword = password => {
  const valid =
    password.match('^(?=.*[a-z])(?=.*[A-Z])(?=.*?[0-9])[a-zA-Z0-9].{7,}$') !==
    null
      ? true
      : false;

  return valid;
};

const isEmail = email => {
  return /\S+@\S+\.\S+/.test(email);
};
export { isLoggedIn, isValidPassword, isEmail, setLoggedIn };
